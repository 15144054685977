import React, { useRef } from "react";
import SectionHeader from "../components/sectionHeader";
import Layout from "../components/layout";
import downArrow from "../assets/icons/icon-down-arrow.png";
import processIcon from "../assets/icons/icon-process.png";
import online from '../assets/online.png'
import "../components/enrol/enrol.scss";
import { Fade } from "react-reveal";

import { useStaticQuery, graphql, navigate } from "gatsby";
import { Helmet } from "react-helmet";

 
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import ProcessFeatures from "../components/features/ProcessFeatures";
import MoreFeaturesLinks from "../components/features/MoreFeaturesLinks";
import FeaturesBackgroundBanner from "../components/features/FeaturesBackgroundBanner";

function Process() {
  const caseProcessData = useStaticQuery(graphql`
    query {
      allStrapiProcessPage {
        nodes {
          KeyFeatures {
            Icon {
              publicURL
            }
            Tab {
              Content
              EventKey
              Header2
              Header3
              Title
            }
            Title
          }
          MoreFeaturesLinks {
            Links {
              LinkTo
              Title
              id
            }
            Title
            TitleHighlight
            id
          }
          PageHeader {
            Content
            Image {
              publicURL
            }
            Text
            Title
            TitleHighlight
          }
          PageTitle
        }
      }
    }
  `);

  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log('Reached bottom')
        navigate('/Purchase');
      }
    }
  };
  const pageData = caseProcessData.allStrapiProcessPage.nodes[0];

    
 

  return (
    <Layout>
      <Helmet defer={false}>
         
        <title>{pageData.PageTitle}</title>
      </Helmet>

      <Container>
        <Row className="enrol-hero">
          <Col md>
            <Fade left>
              <div>
                <SectionHeader
                  icon={processIcon}
                  title={pageData.PageHeader[0].Title}
                  highlight={pageData.PageHeader[0].TitleHighlight}
                  text={pageData.PageHeader[0].Text}
                />
                <ReactMarkdown source={pageData.PageHeader[0].Content} />

                <img src={downArrow} width="60" alt="icon"></img>
              </div>
            </Fade>
          </Col>
          <Col md className="hero-image">
            <img
              src={pageData.PageHeader[0].Image.publicURL}
              className="drop-shadow xyz-in"
              alt="process"
              xyz="fade right stagger delay-4 duration-10"
              width="600"
            ></img>
          </Col>
        </Row>
        </Container>
                {/* Newly added */}
                <Container className="enrol" onScroll={() => onScroll()} ref={listInnerRef}>
                <Row className="enrol-hero">
                    <Col md>
                        <Fade left>
                            <div>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    

                                    <h1><bold>Online</bold></h1>
                                    <h4><bold>Online Applications, Enquiries and events</bold></h4>
                                    <p>Streamline early engagement with families using online application forms. Automatically send your prospectus and accept digital payment of application fees.</p>

                                
                            </div>
                        </Fade>
                    </Col>
                    
                    <Col md className="hero-image">
                        <figure className="drop-shadow xyz-in" xyz="fade right stagger delay-4 duration-10" >
                        <img
              //src={pageData.PageHeader[0].Image.publicURL}
              src={online}
              className="drop-shadow xyz-in"
              alt="mother and son"
              xyz="fade right stagger delay-4 duration-10"
              width="600"
            ></img>
                        </figure>
                       
                         
                    </Col>
                </Row>

               {/* Newly added */} 
        <ProcessFeatures data={pageData.KeyFeatures[0]} />
        <MoreFeaturesLinks data={pageData.MoreFeaturesLinks[0]} />
      </Container>
      <FeaturesBackgroundBanner />
    </Layout>
  );
}

export default Process;
